const LocalStorageService = (() => {
  let _service;

  const _getService = function () {
    if (!_service) {
      _service = this;
      return _service
    }
    return _service
  };

  const _setToken = tokenObj => {
    console.log(tokenObj);
    if(tokenObj){
      if (tokenObj.access) localStorage.setItem('access_token', tokenObj.access);
      if (tokenObj.refresh) localStorage.setItem('refresh_token', tokenObj.refresh);
    }
  };

  const _getAccessToken = () => localStorage.getItem('access_token');
  const _getRefreshToken = () => localStorage.getItem('refresh_token');

  const _clearToken = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  };

  return {
    getService: _getService,
    setToken: _setToken,
    getAccessToken: _getAccessToken,
    getRefreshToken: _getRefreshToken,
    clearToken: _clearToken
  }
})();
export default LocalStorageService;
