import React, { useContext, useState } from 'react';
import {withTranslation} from "react-i18next";
import { Redirect, withRouter } from 'react-router-dom';
import api from '../lib/API';
import LocalStorageService from '../lib/LocalStorageService'
import moment from 'moment';

const localStorageService = LocalStorageService.getService();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      errorMessage: '',
      isLoading: true,
    };
    this.dialog = {};
  }

  componentDidMount() {
    this.loginByToken();
  }

  onKeyDown = (e) => {
    const {username, password} = this.state;
    if(e.keyCode === 13){
      if (username && password) {
        this.onLogin();
      }
      e.preventDefault();
    }
  };
  onChangeData = (value, field) => {
    this.setState({
      [field]: value,
      errorMessage: ''
    })
  };

  onLogin = () => {
    localStorageService.getAccessToken();
    const params = {
      username: this.state.username,
      password: this.state.password,
    };
    api.post('/authenticate', params).then(async (res) => {
      console.log('/authenticate', res.data);
      localStorageService.setToken(res.data);
      this.setState({
        username: '',
        password: '',
        errorMessage: '',
        isLoading: false,
      });
      localStorage.setItem("last_operated",moment().toDate())
      this.setState({isLoading:true})
      setTimeout(() => {
        window.location = "/attendance"
      }, 100)

    }, err => {
      console.log(err.response);
      let msg = '登入名稱/密碼不正確。請重新輸入。';
      if(!err.response){
        msg = '未能連接伺服器。請再試。';
      }
      this.setState({
        // errorMessage: err.response.data.detail,
        errorMessage: msg,
        isLoading: false,
      })
    })
  };

  loginByToken = () => {
    const token = localStorage.getItem("access_token");
    if (!token) {
      this.setState({isLoading:false})
      return;
    }
    localStorage.setItem("last_operated",moment().toDate())
    window.location = "/attendance";
  }

  render() {
    const {username, password, errorMessage, isLoading} = this.state;
      return (
        <div id="front-wrap">
            <div id="login-wrap">
                <img src="https://cmagoodrich.org/cms/images/logo.png" alt="基督教宣道會盈豐堂 -- Christian &amp; Missionary Alliance Goodrich Church" />
                <p class="heading">出席系統</p>
                {errorMessage ? <span className="error">{errorMessage}</span> : null}
                {!isLoading ? <form name="frm_login" id="frm_login" method="post" action="/authenticate">
                    <div><label htmlFor="username">登入名稱</label><input name="username" id="username" type="text" onChange={(e) => this.onChangeData(e.target.value, 'username')} onKeyDown={this.onKeyDown} /></div>
                    <div><label htmlFor="password">密碼</label><input name="password" id="password" type="password" onChange={(e) => this.onChangeData(e.target.value, 'password')} onKeyDown={this.onKeyDown}  /></div>
                    <div class="form-btns"><a href="#" onClick={this.onLogin} className="btn-submit">登入</a></div>
                </form>: null}
            </div>
        </div>
        );
  }
}

export default withTranslation()(withRouter(Login));