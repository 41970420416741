import React, {Component} from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import Login from '../containers/Login';
import MainLayout from '../containers/MainLayout';
import '../styles/App.css';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/attendance" component={MainLayout} />
          <Route exact path="/:secton" component={Login} />
        </Switch>
      </Router>
    );
  }
}

export default App;
