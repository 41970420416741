import axios from 'axios';
import LocalStorageService from "./LocalStorageService";

// LocalstorageService
const localStorageService = LocalStorageService.getService();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
  headers: {
    'content-type': 'application/json',
  }
});

// Add a request interceptor
instance.interceptors.request.use(
  config => {
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers['Authorization'] = 'CMAGD ' + token;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    return Promise.reject(error)
  });

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    return response
  }, error => {
    const originalRequest = error.config;

    if (
      error.request.responseType === 'blob' &&
      error.response.data instanceof Blob &&
      error.response.data.type &&
      error.response.data.type.toLowerCase().indexOf('json') !== -1
    ) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          error.response.data = JSON.parse(reader.result);
          resolve(Promise.reject(error));
        };
        reader.onerror = () => {
          reject(error);
        };
        reader.readAsText(error.response.data);
      });
    }

    if (error.response) {
      if (error.response.status === 401 && originalRequest.url === '/authenticate') {
        return Promise.reject(error);
      }
      if (error.response.status === 401 && originalRequest.url === '/authenticate/refresh') {
        localStorageService.clearToken();
        window.location.href = '/';
        return Promise.reject(error);
      }
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const refreshToken = localStorageService.getRefreshToken();
        return instance.post('/authenticate/refresh', {"refresh": refreshToken})
        .then(res => {
          if (res.status === 200) {
            localStorageService.setToken(res.data);
            instance.defaults.headers.common['Authorization'] = 'CMAGD ' + localStorageService.getAccessToken();
            return instance(originalRequest);
          }else if (res.status >= 400 && res.status < 500){
            localStorageService.clearToken();
          }
        })
      }
    }
    return Promise.reject(error);
  });

export default {
  get: (url, config = {}) => instance.get(url, config),
  delete: (url, config = {}) => instance.delete(url, config),
  post: (url, data = {}, config = {}) => instance.post(url, data, config),
  patch: (url, data = {}, config) => instance.patch(url, data, config)
}
