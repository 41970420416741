import React from 'react';
import api from '../lib/API';
import LocalStorageService from '../lib/LocalStorageService'
import _ from "lodash";
import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import '../styles/MainLayout.css';
import beepMp3 from '../assets/beep-01.mp3';
import beepOgg from '../assets/beep-01.ogg';
import alertBeepMp3 from '../assets/beep-09.mp3';
import alertBeepOgg from '../assets/beep-09.ogg';
const localStorageService = LocalStorageService.getService();

class MainLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            worships: [],
            data: {},
            editEvent: {},
            editEventNameIndex: -1,
            attendants: [],
            attendances: [],
            isEdit: false,
            username: '',
            password: '',
            errorMessage: '',
            idle: true,
            attendPersonName: '',
            attendanceType: ''
        }
    }
    componentDidMount() {
        document.body.removeEventListener('click', this.setLastOperated);
        window.removeEventListener('scroll', this.setLastOperated);
        document.body.removeEventListener('keydown', this.setLastOperated);
        document.body.removeEventListener('keydown', this.startAutoFocus);
        if(document.querySelector(".attendance-panel")){
            document.querySelector(".attendance-panel").removeEventListener('scroll', this.setLastOperated);
        }
        if(document.querySelector(".event-list")){
            document.querySelector(".event-list").removeEventListener('scroll', this.setLastOperated);
        }
        if(this.interval){
            clearInterval(this.interval);
        }
        if(this.foucsInterval){
            clearInterval(this.foucsInterval);
        }
        const token = localStorage.getItem("access_token");
        if (!token){
            window.location = "/login";
        }else{
            this.isOperating();
            this.getEvents();
            document.body.addEventListener('click', this.setLastOperated.bind(this));
            window.addEventListener('scroll', this.setLastOperated.bind(this));
            document.body.addEventListener('keydown', this.setLastOperated.bind(this));
            if(document.querySelector(".attendance-panel")){
                document.querySelector(".attendance-panel").addEventListener('scroll', this.setLastOperated.bind(this));
            }
            if(document.querySelector(".event-list")){
                document.querySelector(".event-list").addEventListener('scroll', this.setLastOperated.bind(this));
            }
            this.interval = setInterval(this.isOperating.bind(this), 1000);
            this.foucsInterval = setInterval(this.startAutoFocus.bind(this), 1000);
        }
    }
    componentWillUnmount(){
        document.body.removeEventListener('click', this.setLastOperated);
        window.removeEventListener('scroll', this.setLastOperated);
        document.body.removeEventListener('keydown', this.setLastOperated);
        document.body.removeEventListener('keydown', this.startAutoFocus);
        if(document.querySelector(".attendance-panel")){
            document.querySelector(".attendance-panel").removeEventListener('scroll', this.setLastOperated);
        }
        if(document.querySelector(".event-list")){
            document.querySelector(".event-list").removeEventListener('scroll', this.setLastOperated);
        }
        if(this.interval){
            clearInterval(this.interval);
        }
        if(this.foucsInterval){
            clearInterval(this.foucsInterval);
        }
    }
    setLastOperated(e){
        const {idle} = this.state;
        if(!idle && localStorage.getItem("last_operated")!= -1){
            if(e.target?.id == "btn-lock-screen"){
                console.log('lock')
                localStorage.setItem("last_operated", -1);
                this.setState({
                    idle:true
                })
            }else{
                console.log('unlock')
                localStorage.setItem("last_operated",moment().toDate());
            }
        }
        if(document.getElementById('attend_token') != document.activeElement){
            document.getElementById("btn-scan").className = "btns";
        }
    }
    isOperating(){
        const {idle} = this.state;
        if(localStorage.getItem("last_operated")){
            let idleTime = moment().diff(moment(localStorage.getItem("last_operated")),'seconds');
            if(idleTime > 120){
                localStorage.setItem("last_operated", -1);
                this.setState({
                    idle:true
                })
            }else{
                if(idle && localStorage.getItem("last_operated") != -1){
                    this.setState({
                        idle:false
                    })
                }    
            }
        }else{
            if(!idle){
                this.setState({
                    idle:true
                })
            }
        }
    }
    onKeyDown = (e) => {
        const {username, password} = this.state;
        if(e.keyCode === 13){
        if (username && password) {
            this.onLogin();
        }
        e.preventDefault();
        }
    };
    onChangeData = (value, field) => {
        this.setState({
        [field]: value,
        errorMessage: ''
        })
    };

    onLogin = () => {
        localStorageService.getAccessToken();
        const params = {
            username: this.state.username,
            password: this.state.password,
        };
        api.post('/authenticate', params).then(async (res) => {
            localStorageService.setToken(res.data);
            localStorage.setItem("last_operated", moment().toDate());
            this.setState({
                username: '',
                password: '',
                errorMessage: '',
                isLoading: false,
                idle: false
            });

        }, err => {
            console.log(err.response);
            let msg = '登入名稱/密碼不正確。請重新輸入。';
            if (!err.response) {
                msg = '未能連接伺服器。請再試。';
            }
            this.setState({
                // errorMessage: err.response.data.detail,
                errorMessage: msg,
                isLoading: false,
            })
        })
    };
    startAutoFocus(){
        if(localStorage.getItem("last_operated")){
            let idleTime = moment().diff(moment(localStorage.getItem("last_operated")),'seconds');
            if(idleTime > 5){
                let tokenInput = document.getElementById('attend_token');
                if(document.getElementById('username') != document.activeElement && document.getElementById('password') != document.activeElement)
                if(tokenInput != document.activeElement && !this.state.isEdit && this.state.editEventNameIndex<0){
                    this.setTokenFocus();
                }
            }
        }
    }
    setTokenFocus(){
        document.getElementById('attend_token').focus();
        document.getElementById("btn-scan").className = "btns selected";
    }
    async addWorkshipEvent(e, type){
        const {data, worships} = this.state;
        let edit_worships = worships;
        let new_worship_event = {
            worship_id : "",
            title:"",
            speech_date:moment().format("YYYY-MM-DD"),
            speaker:"",
            event_id: "",
            session: "",
            event_type: type,
        };
        edit_worships.unshift(new_worship_event)
        this.setState({
            worships:edit_worships,
            data: new_worship_event,
            attendants: [],
            attendances: [],
            isEdit: true,
        })
    }
    async createWorshipEvent(e, isEdit, type){
        const {data, worships} = this.state; 
        const params = {}
        params.speech_date = data.speech_date;
        params.title = data.title;
        params.speaker = data.speaker;
        if(data?.worship_id)
            params.worship_id = data.worship_id;
        if(type == "event"){
            params.guide_id = 0;
            await api.post("/event", params).then(
                rs => {
                    const event_data = _.get(rs, "data") || [];
                    let edit_worships = worships;
                    let edit_data = data;
                    let edit_worship_index = edit_worships.findIndex((worship,w)=>worship.event_type == type);
                    edit_worships[edit_worship_index] = {
                        ...edit_worships[edit_worship_index],
                        ...params,
                        event_id: event_data.event.event_id,
                        session:""
                    }
                    this.setState({
                        editEvent : edit_data,
                        worships : edit_worships,
                        data : edit_data,
                        isEdit:false
                    },()=>{
                        this.viewWorkship(e, edit_worships[edit_worship_index]);
                    })
            },err => {
                if(this.errorMsg){
                    clearTimeout(this.errorMsg);
                }
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            });
        }else{
            await api.post("/worship", params).then(
                rs => {
                const worship_data = _.get(rs, "data") || [];
                let edit_worships = worships;
                let edit_worship_index = edit_worships.findIndex((worship,w)=>worship.event_type == type);
                edit_worships[edit_worship_index] = {
                    ...edit_worships[edit_worship_index],
                    ...params,
                    worship_id: worship_data.worship.worship_id,
                }
                this.setState({
                    worships:edit_worships,
                    isEdit:false
                },()=>{
                    this.viewWorkship(e, edit_worships[edit_worship_index]);
                })
            })
        }

    }
    async updateWorshipEvent(e, isEdit, event_id){
        if(!isEdit){
            this.setState({
                isEdit:true
            })
            return;
        }
        event_id = event_id || 0;
        const {data, worships} = this.state; 
        const params = {}
        params.speech_date = data.speech_date;
        params.title = data.title;
        params.speaker = data.speaker;
        if(data?.worship_id)
            params.worship_id = data.worship_id;
        if(event_id){
            params.event_id = event_id;
            await api.patch("/event", params).then(
                rs => {
                    let edit_worships = worships;
                    let edit_data = data;
                    let edit_worship_index = edit_worships.findIndex((worship,w)=>worship.event_id == edit_data.event_id);
                    edit_worships[edit_worship_index] = {
                        ...edit_worships[edit_worship_index],
                        ...params,
                        session:""
                    }
                    this.setState({
                        editEvent : edit_data,
                        worships : edit_worships,
                        data : edit_data,
                        isEdit:false
                    })                        
                },err => {
                    if(this.errorMsg){
                        clearTimeout(this.errorMsg);
                    }
                    this.setState({
                        // errorMessage: err.response.data.detail,
                        errorMessage: err?.response?.data?.message,
                        isLoading: false,
                    },()=>{
                        this.errorMsg = setTimeout(()=>{
                            this.setState({
                                errorMessage: ""
                            });
                        },3000)
                    })
                })
    
        }else{
            await api.patch("/worship", params).then(
                rs => {
                let edit_worships = worships;
                let edit_worship_index = edit_worships.findIndex((worship,w)=>worship.worship_id == params.worship_id);
                edit_worships[edit_worship_index] = {
                    ...edit_worships[edit_worship_index],
                    ...params
                }
                this.setState({
                    worships:edit_worships,
                    isEdit:false
                },err => {
                    if(this.errorMsg){
                        clearTimeout(this.errorMsg);
                    }
                    this.setState({
                        // errorMessage: err.response.data.detail,
                        errorMessage: err?.response?.data?.message,
                        isLoading: false,
                    },()=>{
                        this.errorMsg = setTimeout(()=>{
                            this.setState({
                                errorMessage: ""
                            });
                        },3000)
                    })
                })
            })
        }
    }
    async createEvent(e, index){
        const {data, worships} = this.state; 
        if(!e.target.value){
            let edit_data = data;
            edit_data.events = edit_data.events.slice(0,-1);
            this.setState({
                data : edit_data,
                editEvent: edit_data.events[0],
                editEventNameIndex:-1
            },()=>{
                if(this.state.editEvent?.event_id){
                    this.getAttendants();
                }    
            });
            return;
        }
        const params = {}
        params.speech_date = data.speech_date;
        params.title = e.target.value;
        params.speaker = data.speaker;
        params.guide_id = data.worship_id || 0;
        await api.post("/event", params).then(
            rs => {
                const event_data = _.get(rs, "data") || [];
                let edit_worships = worships;
                let edit_data = data;
                let edit_worship_index = edit_worships.findIndex((worship,w)=>worship.worship_id == edit_data.worship_id);
                edit_data.events[index] = {
                    ...event_data.event,
                    worship_id:edit_data.worship_id,
                    title:edit_data.title
                };
                edit_worships[edit_worship_index] = edit_data;
                this.setState({
                    editEvent : edit_data.events[index],
                    worships : edit_worships,
                    data : edit_data,
                    editEventNameIndex:-1
                },()=>{
                    if(this.state.editEvent?.event_id){
                        this.getAttendants();
                    }    
                });
            },err => {
                if(this.errorMsg){
                    clearTimeout(this.errorMsg);
                }
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            })

    }
    async updateEvent(e, index){
        const {data, worships} = this.state; 
        const params = {}
        const current_event = data.events[index];
        if(e.target.value != current_event["session"]){
            params.speech_date = current_event.speech_date;
            params.title = e.target.value;
            params.speaker = current_event.speaker;
            params.event_id = current_event.event_id;
        }
        await api.patch("/event", params).then(
            rs => {
                let edit_worships = worships;
                let edit_data = data;
                let edit_worship_index = edit_worships.findIndex((worship,w)=>worship.worship_id == edit_data.worship_id);
                edit_data.events[index] = {
                    ...current_event,
                    speech_date : params.speech_date,
                    session: params.title,
                    speaker: params.speaker
                }
                edit_worships[edit_worship_index] = edit_data;
                this.setState({
                    editEvent : edit_data.events[index],
                    worships : edit_worships,
                    data : edit_data,
                    editEventNameIndex:-1
                })                        
            },err => {
                if(this.errorMsg){
                    clearTimeout(this.errorMsg);
                }
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            })

    }
    async getEvents() {
        const {idle} = this.state;
        await api.get("/worship").then(
            rs => {
                if(!idle && localStorage.getItem("last_operated")!=-1){
                    localStorage.setItem("last_operated",moment().toDate());
                    this.setState({
                        idle:false
                    })
                }
                const worship_data = _.get(rs, "data") || [];
                api.get("/event").then(
                    rs_event => {
                        const event_data = _.get(rs_event, "data") || [];
                        for(var e in event_data){
                            let event = event_data[e];
                            if(event["worship_id"]){
                                let worship_index = worship_data.findIndex(worship=>worship["worship_id"]==event["worship_id"]);
                                if(!worship_data[worship_index]["events"]){
                                    worship_data[worship_index]["events"] = [];
                                }
                                worship_data[worship_index]["events"].push(event);
                            }else{
                                worship_data.push(event);
                            }
                        }
                        this.setState({
                            worships : _.sortBy(worship_data,["speech_date"]).reverse()
                        },()=>{
                            this.viewWorkship(e, this.state.worships[0]);
                        });        
                    });
            }).catch( e =>{
                window.location = "/"
            });
    }
    async getAttendants() {
        await api.get("/attendant").then(
            rs => {
                let attendants = [];
                const attendant_data = _.get(rs, "data") || [];
                for(var a in attendant_data){
                    let attendant = attendant_data[a];
                    let user_group_name = attendant.user_group_name == null ? "新朋友" :attendant.user_group_name;
                    let grp_idx = attendants.findIndex(user_group=>user_group.user_group_name == user_group_name);
                    if(grp_idx < 0){
                        grp_idx = attendants.length;
                        attendants.push({
                            user_group_name:user_group_name,
                            user_cells: []
                        })
                    }
                    let user_cell_name = attendant.user_cell_name == null ? "新朋友" :attendant.user_cell_name;
                    user_cell_name = user_cell_name != user_group_name ? user_cell_name : "";
                    let cell_idx = attendants[grp_idx]["user_cells"].findIndex(user_cell=>user_cell.user_cell_name == user_cell_name);
                    if(cell_idx < 0){
                        cell_idx = attendants[grp_idx]["user_cells"].length;
                        attendants[grp_idx]["user_cells"].push({
                            user_cell_name:user_cell_name,
                            attendants: []
                        })
                    }
                    attendants[grp_idx]["user_cells"][cell_idx]["attendants"].push(attendant);
                }
                this.setState({
                    attendants:attendants
                },()=>{
                    this.setTokenFocus();
                })
        },err => {
            if(this.errorMsg){
                clearTimeout(this.errorMsg);
            }
            this.setState({
                // errorMessage: err.response.data.detail,
                errorMessage: err?.response?.data?.message,
                isLoading: false,
            },()=>{
                this.errorMsg = setTimeout(()=>{
                    this.setState({
                        errorMessage: ""
                    });
                },3000)
            })
        });
    }
    async getAllAttendance(event_id){
        await api.get("/attendance/"+event_id).then(
            rs => {
                const attendance_data = _.get(rs, "data") || [];
                this.setState({
                    attendances: attendance_data
                })
        },err => {
            if(this.errorMsg){
                clearTimeout(this.errorMsg);
            }
            this.setState({
                // errorMessage: err.response.data.detail,
                errorMessage: err?.response?.data?.message,
                isLoading: false,
            },()=>{
                this.errorMsg = setTimeout(()=>{
                    this.setState({
                        errorMessage: ""
                    });
                },3000)
            })
        });
    }
    async postAttendance(e, event_id, attendant_id, attendance){
        const {data, worships, attendPersonName} = this.state; 
        const params = {}
        params.attendance = attendance;
        await api.post("/attendance/"+event_id+"/"+attendant_id+"/", params).then(
            rs => {
                const attendance_data = _.get(rs, "data") || [];
                if(this.attendPersonNamePrompt){
                    clearTimeout(this.attendPersonNamePrompt);
                }
                this.setState({
                    attendPersonName: attendPersonName + (attendPersonName ? ",": "") + attendance_data.person_name,
                    attendanceType: attendance,
                },()=>{
                    this.attendPersonNamePrompt = setTimeout(()=>{
                        this.setState({
                            attendPersonName:"",
                            attendanceType:""
                        })
                    },3000);
                })
                this.getAllAttendance(event_id);
            },err => {
                if(this.errorMsg){
                    clearTimeout(this.errorMsg);
                }
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            })

    }
    async postAttendanceByToken(e, event_id, token){
        e.target.value = "";
        const {data, worships, attendPersonName} = this.state; 
        const params = {}
        params.attendance = "A";
        params.token = token;
        await api.post("/attendance/"+event_id+"/token/", params).then(
            rs => {
                const attendance_data = _.get(rs, "data") || [];
                if(this.attendPersonNamePrompt){
                    clearTimeout(this.attendPersonNamePrompt);
                }
                this.setState({
                    attendPersonName: attendPersonName + (attendPersonName ? ",": "") + attendance_data.person_name
                },()=>{
                    this.attendPersonNamePrompt = setTimeout(()=>{
                        this.setState({
                            attendPersonName:""
                        })
                    },3000);
                })
                document.getElementById("beep-confirm").pause();
                document.getElementById("beep-confirm").currentTime = 0;
                document.getElementById("beep-confirm").play();
                this.getAllAttendance(event_id);
            },err => {
                if(this.errorMsg){
                    clearTimeout(this.errorMsg);
                }
                document.getElementById("beep-alert").pause();
                document.getElementById("beep-alert").currentTime = 0;
                document.getElementById("beep-alert").play();
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            }).catch( err =>{
                document.getElementById("beep-alert").pause();
                document.getElementById("beep-alert").currentTime = 0;
                document.getElementById("beep-alert").play();
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            });

    }
    async createAttendant(e, is_kid, is_teens){
        const {attendants} = this.state; 
        if(!e.target.value){
            return;
        }
        is_kid = is_kid || false;
        is_teens = is_teens || false;
        const params = {}
        params.person_name = e.target.value;
        params.is_kid = is_kid;
        params.is_teens = is_teens;
        await api.post("/attendant", params).then(
            rs => {
                const attendant_data = _.get(rs, "data") || [];
                e.target.value = "";
                let edit_attendants = attendants;
                let insert_group_name = is_kid ? "兒童":(is_teens ? "學生":"新朋友");
                let group_index = edit_attendants.findIndex((group,g)=>group.user_group_name == insert_group_name);
                edit_attendants[group_index]["user_cells"][0]["attendants"].push(attendant_data.attendant)
                this.setState({
                    attendants : edit_attendants
                },()=>{
                    if(this.state.editEvent?.event_id){
                        this.getAttendants();
                        this.postAttendance(e, this.state.editEvent.event_id, attendant_data.attendant.attendant_id, "A");
                    }    
                });
            },err => {
                if(this.errorMsg){
                    clearTimeout(this.errorMsg);
                }
                document.getElementById("beep-alert").pause();
                document.getElementById("beep-alert").currentTime = 0;
                document.getElementById("beep-alert").play();
                this.setState({
                    // errorMessage: err.response.data.detail,
                    errorMessage: err?.response?.data?.message,
                    isLoading: false,
                },()=>{
                    this.errorMsg = setTimeout(()=>{
                        this.setState({
                            errorMessage: ""
                        });
                    },3000)
                })
            })

    }

    viewWorkship(e, worship, idx){
        idx = idx || 0;
        this.setState({
            data : {}
        },()=>{
            this.setState({
                data : worship?.event_id ? {...worship, title:worship.session} : worship,
                isEdit: false,
                editEvent: worship?.events && worship.events.length ? worship.events[idx] : (worship?.event_id ? {...worship, title:worship.session} :{}),
                attendants: [],
                attendances: [],
            },()=>{
                if(this.state.editEvent?.event_id){
                    this.getAttendants();
                    this.getAllAttendance(this.state.editEvent.event_id);
                }    
            })    
        })
    }
    viewEvent(e, event){
        if(this.state.editEventNameIndex < 0){
            this.setState({
                editEvent: event,
                attendants: [],
                attendances: [],
            },()=>{
                if(this.state.editEvent?.event_id){
                    this.getAttendants();
                    this.getAllAttendance(this.state.editEvent.event_id);
                }    
            })                
        }
    }
    handleWorshipChange(value, key){
        this.setState({
            data : { 
                ...this.state.data,
                [key]: value
            }
        });
    }
    handleDateChange(date){
        console.log(date);
        this.setState({
            data : { 
                ...this.state.data,
                speech_date: moment(date).format("YYYY-MM-DD")
            }
        });
    }
    onLogout = (isLogout) => {
        console.log("onLogout");
        if (isLogout) {
          // clear user data & go to login
          // localStorage.removeItem("access_token");
          localStorage.clear();
          window.location = "/login";
        }
    };
    render() {
        const {events, worships, data, isEdit, editEvent, attendants, attendances, editEventNameIndex, errorMessage, idle, attendPersonName, attendanceType} = this.state;
        return <div id="event-attendance">
            <div className={`event-list ${idle?" locked":""}`}>
                <div className="header">
                    <h2>崇拜/聚會</h2>
                    <button 
                        className="btns"
                        onClick={(e)=>{
                            this.addWorkshipEvent(e, 'worship');
                        }}
                    >+ 崇拜</button>
                    <button 
                        className="btns"
                        onClick={(e)=>{
                            this.addWorkshipEvent(e, 'event');
                        }}
                    >+ 活動</button>
                </div>
                <ul>
                    {worships.length ? worships.map((worship, idx) => {
                        return <li key={idx} className={`${worship.worship_id == data.worship_id && worship.event_id == data.event_id ? "selected" : ""}`}>
                            <strong onClick={(e)=>{
                            this.viewWorkship(e, worship);
                        }}><span className="date">{moment(worship.speech_date).format("YYYY年M月D日")}</span> {worship.title}{worship.session || ""}<span>{worship.speaker || ""}</span></strong>
                            <ul>{worship.events && worship.events.length ? 
                            worship.events.map((event, e_idx) => {
                                return <li key={e_idx} className={`${event.event_id == editEvent.event_id ? "selected" : ""}`} onClick={(e)=>{
                                    setTimeout(()=>{
                                        if(worship.worship_id == data.worship_id){
                                            this.viewEvent(e,event, e_idx);
                                        }else{
                                            this.viewWorkship(e, worship, e_idx);
                                        }
                                    },1000);
                                }}>{event.speech_date != worship.speech_date ? <span className="date">{moment(event.speech_date).format("YYYY年M月D日")+" "}</span> : ""}{event.session}</li>
                            }) : null}</ul>
                            {/* {worship.worship_id ? <button className="btns">+</button> : null} */}
                        </li>
                    }) : null}
                    {/* {events.length ? events.map((event, idx) => {
                        return <li>{event.title} {event.session}</li>
                    }) : null} */}
                </ul>
            </div>
            <div className={`event-panel ${idle?" locked":""}`}>
                <div className="header">
                <DatePicker 
                    selected={data.speech_date ? moment(data.speech_date).toDate() : null} 
                    dateFormat="yyyy年M月d日"
                    disabled={!isEdit}
                    className={`speech-date ${isEdit ? "editable" : ""}`}
                    onChange={this.handleDateChange.bind(this)} />
                    <input type="text" placeholder={isEdit ? "標題/活動":""} id="title" className={isEdit ? "editable" : ""} name="title" defaultValue={data?.title || ""} onChange={(e) => this.handleWorshipChange(e.target.value, 'title')} disabled={!isEdit} required="" />
                    <input type="text" placeholder={isEdit ? "講員" : ""} id="speaker" className={isEdit ? "editable" : ""} name="speaker" defaultValue={data?.speaker || ""} onChange={(e) => this.handleWorshipChange(e.target.value, 'speaker')} disabled={!isEdit} required="" />
                    {data?.worship_id || data?.event_id || data?.event_type ? <button onClick={(e)=>{
                        if(data?.worship_id || data?.event_id){
                            this.updateWorshipEvent(e, isEdit, data?.event_id);
                        }else if(data?.event_type){
                            this.createWorshipEvent(e, isEdit, data.event_type);
                        }
                    }} className="btn-edit btns">{isEdit?"儲存":"編輯"}</button>
                    : null}
                </div>
                {data?.worship_id ? <div className="tab">
                    {data.events ? data.events.map((event, e_idx) => {
                        return <span key={e_idx} onClick={(e)=>{
                            setTimeout(()=>{
                                this.viewEvent(e,event, e_idx);
                            },1000);
                        }} className={`btns ${event.event_id == editEvent.event_id ? "selected" : ""}`} style={editEventNameIndex == e_idx?{backgroundColor:'#fff',border:'1px solid #ab3e97'}:{}}><span onClick={(e)=>{
                        }} onDoubleClick={(e)=>{
                            e.stopPropagation();
                            this.setState({
                                editEventNameIndex: e_idx
                            },()=>{
                                e.target.focus();
                                e.target.select();
                            })
                        }}><input type="text" disabled={editEventNameIndex!=e_idx} defaultValue={event.session} size={event.session.length+1} style={editEventNameIndex==e_idx?{color:'#333'}:{}} onKeyDown={(e)=>{
                            if(e.keyCode === 13){
                                if(event.event_id){
                                    this.updateEvent(e, e_idx);
                                }else{
                                    this.createEvent(e, e_idx);
                                }
                            }
                        }} onBlur={(e)=>{
                            if(event.event_id){
                                this.updateEvent(e, e_idx);
                            }else{
                                this.createEvent(e, e_idx);
                            }
                    }} /></span></span>
                    })
                    : null}
                    <button onClick={(e)=>{
                        let edit_data = data;
                        if(!edit_data.events){
                            edit_data.events = [];
                        }
                        edit_data.events.push({
                            title:data.title,
                            speech_date:data.speech_date,
                            speaker:data.speaker,
                            session:"",
                            event_id:0,
                            worship_id:data.worship_id
                        })
                        this.setState({
                            data: edit_data,
                            editEventNameIndex: data.events.length -1
                        });
                    }} className="btns" style={{position:'absolute', right:5}}>+</button>
                </div> : <div className="tab"></div>}
                <div className="attendance-panel">
                    {attendants && attendants.length ? <h3>出席記錄</h3>:null}
                    {attendants && attendants.length ? <ul className="user-group">
                        {attendants.map((group, g)=>{
                            return <li key={g}><strong>{group.user_group_name}</strong>
                                <ul className="user-cell">
                                    {group.user_cells.map((cell, c)=>{
                                        return <li key={`${g}_${c}`}><strong>{cell.user_cell_name}</strong>
                                        <ul className="attendant-list">
                                            {cell.attendants.map((attend, a)=>{
                                                let person_attendance = attendances.find((attendance,a)=>attendance.attendant_id==attend.attendant_id);
                                                return <li key={`${g}_${c}_${a}`}>
                                                    <strong>{idle? "會友":attend.person_name}</strong>
                                                    <button 
                                                        className={`btns ${person_attendance && person_attendance.attendance=="A"?"selected":"cancel"}`}
                                                        onClick={(e)=>{
                                                            if(person_attendance?.attendance!="A"){
                                                                this.postAttendance(e, editEvent.event_id, attend.attendant_id, "A");
                                                            }
                                                        }}
                                                    >出席</button>
                                                    <button 
                                                        className={`btns default ${person_attendance && person_attendance.attendance=="N"?"selected":""}`}
                                                        onClick={(e)=>{
                                                            if(person_attendance?.attendance!="N"){
                                                                this.postAttendance(e, editEvent.event_id, attend.attendant_id, "N");
                                                            }
                                                        }}
                                                    >取消</button>
                                                </li>
                                            })}
                                        </ul>
                                        </li>
                                    })}
                                </ul>
                            </li>
                        })}
                    </ul> : null}
                </div>
                <div className="footer">
                    <label htmlFor="new_person_name">新朋友</label>
                    <input type="text" id="new_person_name" className="editable" name="person_name" onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            this.createAttendant(e);
                        }
                    }} />
                    <label for="new_kid_person_name" style={{marginLeft: 13}}>兒童新朋友</label><input type="text" id="new_kid_person_name" class="editable" name="kid_person_name" onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            this.createAttendant(e, true);
                        }
                    }} />
                    <label for="new_teens_person_name" style={{marginLeft: 13}}>青少崇新朋友</label><input type="text" id="new_teens_person_name" 
class="editable" name="teens_person_name" onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            this.createAttendant(e, false, true);
                        }
                    }} />
                    <input type="text" id="attend_token" className="editable" name="attend_token" onKeyDown={(e)=>{
                        if(e.keyCode === 13){
                            if(e.target.value)
                                this.postAttendanceByToken(e, editEvent.event_id, e.target.value);
                        }
                    }} />
                    <button onClick={(e)=>{
                        this.setTokenFocus();
                    }} className="btns" id="btn-scan">掃描</button>
                    {/* {errorMessage? <span className="error">{errorMessage}</span> : null} */}
                    {/* <button onClick={(e)=>{
                        
                    }} className="btn-add btns">出席</button> */}
                    <button id="btn-lock-screen" className="btns" style={{position:'absolute',right:70}}>鎖定</button>
                    <button onClick={(e)=>{
                        this.onLogout(true);
                    }} className="btns" style={{position:'absolute',right:10}}>登出</button>
                </div>
            </div>
            {idle ? <div id="front-wrap">
                <div id="login-wrap">
                    <img src="https://cmagoodrich.org/cms/images/logo.png" alt="基督教宣道會盈豐堂 -- Christian &amp; Missionary Alliance Goodrich Church" />
                    <p className="heading">出席系統</p>
                    {errorMessage ? <span className="error">{errorMessage}</span> : null}
                    <form name="frm_login" id="frm_login" method="post" action="/authenticate">
                        <div><label htmlFor="username">登入名稱</label><input name="username" id="username" type="text" onChange={(e) => this.onChangeData(e.target.value, 'username')} onKeyDown={this.onKeyDown} /></div>
                        <div><label htmlFor="password">密碼</label><input name="password" id="password" type="password" onChange={(e) => this.onChangeData(e.target.value, 'password')} onKeyDown={this.onKeyDown}  /></div>
                        <div className="form-btns"><a href="#" onClick={this.onLogin} className="btn-submit">登入</a></div>
                    </form>
                </div>
                {editEvent ? <div id="event-title-wrap">{moment(editEvent.speech_date).format("YYYY年M月D日")} {editEvent.session}</div> : ""}
            </div>: null}
            {attendPersonName ? <div id="prompt-wrap">
            <p className="heading">{attendPersonName}已{attendanceType=="N"?"取消":"簽到"}</p>
            </div> : null}
            {errorMessage ? <div id="error-wrap">
            <p className="heading" style={{color:'red'}}>{errorMessage}</p>
            </div> : null}
            <audio id="beep-confirm">
                <source src={beepOgg} type="audio/ogg" />
                <source src={beepMp3} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
            <audio id="beep-alert">
                <source src={alertBeepOgg} type="audio/ogg" />
                <source src={alertBeepMp3} type="audio/mpeg" />
                Your browser does not support the audio element.
            </audio>
        </div>;
    }
}

export default MainLayout;